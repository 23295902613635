
import React, { useState } from 'react';
import FirstForm from './FirstForm';
import SecondForm from './SecondForm';
import ThirdForm from './ThirdForm';
import endPoints from '../constants';
import useFetch from '../hooks/useFetch';
import MainHeader from '../components/MainHeader';

const MultiStepForm = () => {
    const [step, setStep] = useState(0);
    const [businessData,setBusinessData]=useState({})
    const [business,setBusiness]=useState({})
    const handlePrev = () => setStep(step - 1);
    const handleNext = () => {
        setStep(step + 1);
    };
    // fetch bussiness detail
    const { data, dataLoading } = useFetch(`${endPoints.GET_BUSINESS_ACCOUNT}`, 1);
    // breadcrumb routes
    const routes = [
        { name: 'Home', path: '/' },
        { name: `${window.location.pathname === "/accounts" ? "Accounts" : "Business Accounts"}`, path: '' },
    ];
    return (
        <div>
            <MainHeader />
            {step === 0 && <FirstForm handleNext={() => handleNext()} setBusinessData={setBusinessData} Business_list={data} setBusiness={setBusiness} dataLoading={dataLoading} routes={routes}/>}
            {step === 1 && <SecondForm handlePrev={() => handlePrev()} handleNext={() => handleNext()} setBusinessData={setBusinessData} businessData={businessData} />}
            {step === 2 && <ThirdForm handlePrev={() => handlePrev()} handleNext={() => handleNext()} setBusinessData={setBusinessData} businessData={businessData} business={business} routes={routes}/>}
        </div>
    );
};

export default MultiStepForm;
