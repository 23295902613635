
import { Link, useNavigate } from "react-router-dom"
import logo from "../assets/images/logo.png"
import profile from "../assets/images/user.svg"
import { useEffect, useState } from "react"
// import baseUrl from "../baseUrl"
import endPoints from "../constants"
import axios from "axios"
import { useAuth } from "./AuthContext"
import { IntercomProvider, useIntercom } from "react-use-intercom"
import SardineService from "../hooks/SardineService"
import { decryptData } from "../hooks/encryptionUtils"
import Cookies from 'js-cookie';

const MainHeader = () => {
  const { boot, shutdown, hide, show, update } = useIntercom();
  let [userDetails, setUserDetails] = useState(null)
  const navigate = useNavigate();
  const { userRole } = useAuth();
  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("url");
    shutdown();
    handleSardineLogout()
    navigate("/login")
  }
  const user = localStorage.getItem("skyfi");
  const tk = JSON.parse(user);
  const getUserDetails = async () => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}${endPoints.ME}`,
      {
        headers: {
          'Authorization': `Bearer ${tk.access_token}`
        }
      }
    );
    if (resp.status == 200) {
      // setUserDetails(resp?.data)
      let user = JSON.stringify(resp?.data)
      localStorage.setItem("user", user)
      // toast.success("Business Created");

    }
  }
  const userd = localStorage.getItem("user");

  // const ud = JSON.parse(userd);
  useEffect(() => {
    if (userd) {
      try {
        const decrypted = decryptData(userd);
        if (tk && !decrypted) getUserDetails();
        if (decrypted) {
          setUserDetails(decrypted)
        }
        // setUserRole(decrypted);
      } catch (error) {
        console.error('Decryption error:', error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  }, [userd])

// sardine logout
  function handleSardineLogout  ()  {
    // After logout, reset userIdHash and sessionKey
    SardineService.updateConfig({
      userIdHash: null,
      sessionKey: "NEW_SESSION_KEY", // Generate new session key
      flow: "/login" // Example flow after logout
    });
  };



  const [fixedHeader, setFixedHeader] = useState(false);

useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY >= 0) {
            setFixedHeader(true);
        } else {
            setFixedHeader(false);
        }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <header className={`header ${fixedHeader ? 'fixed-header' : ''}`}>
 
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="dashboard_bar cursor-pointer" onClick={() => { navigate("/") }}>
                <img src={logo} />
              </div>
            </div>
            <div className="navbar-nav header-right"> 
            <div className="dropdown">
  <a
    className="me-3 dropdown-toggle hidden-arrow"
    href="#"
    id="navbarDropdownMenuLink"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {/* <i className="fas fa-bell" /> */}
    <i class="fa-regular fa-bell"></i>
    <span className="badge rounded-pill badge-notification">1</span>
  </a>
  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
    <li>
      <a className="dropdown-item" href="#">Some news</a>
    </li>
    <li>
      <a className="dropdown-item" href="#">Another news</a>
    </li>
    <li>
      <a className="dropdown-item" href="#">Something else here</a>
    </li>
  </ul>
</div>

              <ul className="navbar-nav header-right">
                <li className="nav-item dropdown header-profile">
                  <a
                    className="nav-link"
                
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <img src={profile} width={20} alt="" />
                    <div className="header-info">
                      <span className="text-black">
                        <strong className="text-capitalize">{userDetails?.person?.first_name} {userDetails?.person?.last_name}</strong>
                      </span>
                      {/* <p className="fs-12 mb-0 text-capitalize">{userDetails?.role}</p> */}
                    </div>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <div className="pro-dtl ">
                      <h3 className="text-capitalize">{userDetails?.person?.first_name} {userDetails?.person?.last_name}</h3>
                      <p>{userDetails?.phone}</p>
                      <p>{userDetails?.email}</p>
                    </div>
                    {userRole != "edit_account" && userRole !="view_account" && userRole !="card_only" &&
                    <>
                       <Link to="/staff-list" className="dropdown-item ai-icon">
                      <span className="ms-2">Staff </span>
                    </Link>
                    <Link to="/external-account" className="dropdown-item ai-icon">
                      <span className="ms-2">External Accounts </span>
                    </Link>
                    </>
                    }
                    <Link to={endPoints.disclosures} className="dropdown-item ai-icon">
                      <span className="ms-2">Disclosures </span>
                    </Link>
                    <Link to="" onClick={show} className="dropdown-item ai-icon">
                      <span className="ms-2">Get Help </span>
                    </Link> 
                    {/* <Link to="/settings" className="dropdown-item ai-icon">
                      <span className="ms-2">Settings </span>
                    </Link> */}
                    <Link to="/accounts" className="dropdown-item ai-icon">
                      <span className="ms-2">Accounts </span>
                    </Link>
                    <Link to="/" className="dropdown-item ai-icon">
                      <span className="ms-2">Business </span>
                    </Link>
                    {
                      userDetails?.is_cd_account == true && <Link to="/certificate-of-deposit" className="dropdown-item ai-icon">
                        <span className="ms-2">Certificate Of Deposit </span>
                      </Link>
                    }
                    <a className="dropdown-item ai-icon cursor-pointer" onClick={handleLogout}>
                      <span className="ms-2">Sign Out </span>
                    </a>
                  </div>
                </li>
              </ul>
              {/* {window.location.pathname != "/" && <div className="back-to-last" onClick={() => { navigate(-1) }}>
                <a className="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={15}
                    height={15}
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="currentColor"
                      d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                    />
                    <path
                      fill="currentColor"
                      d="m237.248 512l265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                    />
                  </svg>{" "}
                  Back
                </a>
              </div>} */}
            </div>
          </div>
        </nav>
      </div>

    </header>

  )
}

export default MainHeader