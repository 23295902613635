import React from 'react'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import SideDefault from '../components/SideDefault';
import profile from '../assets/images/no-user.svg';
import MainHeader from "../components/MainHeader";

const ExternalAccount = () => {
    const navigate = useNavigate()
    const { data: externalAccountDAta, dataLoading: externalAccountLoading } = useFetch(endPoints.GET_BY_ROLE);
    // loading bars
    const loading = () => {
        if (externalAccountLoading) {
            return true
        }
        else return false
    }
    const acc_slug = (item) => {
        return item?.uuid + "_" + item?.bank_name?.trim()?.toLowerCase()?.replaceAll(' ', "-")
    }

    return (
        <>
                    <MainHeader />

            {!loading() &&
                <div className="s-layout">
                    <SideDefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
                                                    <path fill="currentColor" d="M9.993 10.573a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078c.047.367-.226.7-.61.745c-.383.045-.733-.215-.78-.582c-.54-4.19-4.169-7.345-8.57-7.345c-4.425 0-8.101 3.161-8.64 7.345c-.047.367-.397.627-.78.582c-.384-.045-.657-.378-.61-.745c.496-3.844 3.281-6.948 6.975-8.068A6 6 0 0 1 10 0Z" />
                                                </svg> External Accounts</h4>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <h5>Link External Account</h5>
                                                    <ul className="tab-links">
                                                        <li>
                                                            <a onClick={() => navigate("/link-account")} className='cursor-pointer'>
                                                                Link External Account
                                                                <svg
                                                                    width={19}
                                                                    height={19}
                                                                    viewBox="0 0 19 19"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17.832 9.49976H1.16537"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeMiterlimit={10}
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-xl-12 pt-2">
                                                    <h5>Account</h5>

                                                    <ul className="detail-text ext-account" >
                                                        {externalAccountDAta?.data?.length > 0 && externalAccountDAta?.data?.map((item, id) => {
                                                            return (
                                                                <li key={id} className='cursor-pointer' onClick={() => { navigate(`/relink-external-account/${acc_slug(item?.attributes)}`, { state: item }) }}>
                                                                    <span>

                                                                        <span>Bank name: </span>
                                                                        <strong>{item.attributes.bank_name}</strong>
                                                                    </span>
                                                                    <span>

                                                                        <span>Routing Number: </span>
                                                                        <strong>{item.attributes?.routing_number}</strong>
                                                                    </span>
                                                                    <span>

                                                                        <span>Last Four: </span>
                                                                        <strong>{item.attributes?.account_number?.slice(-4)}</strong>
                                                                    </span>
                                                                </li>)
                                                        })}
                                                        {externalAccountDAta?.data?.length == 0 && <p className="">No Data Found</p>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading()}
            />
        </>
    )
}

export default ExternalAccount