import React from 'react'
import MainHeader from '../components/MainHeader'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../components/Sidebar';
import SideDefault from '../components/SideDefault';

const AddCard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
        <>
            <MainHeader />
            <div className="s-layout">
                <SideDefault />
                <main className="s-layout__content">
                    <div className="main-page">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card plan-list">
                                    <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                        <div className="me-auto pe-3 mb-3">
                                            <h4 className="text-black fs-26 mb-3">Add Card</h4>
                                        </div>
                                    </div>
                                    <div className="card-body  pt-2">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <h5>Create a Card</h5>
                                                <ul className="tab-links">
                                                    {params?.card_name != "mastercard" && <li>
                                                        <a className="cursor-pointer" onClick={() => { navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards/add-card/${params?.card_name?.toLowerCase()}/physical/create-card`, { state: { type: "physical", card: location?.state?.card, uuid: location?.state?.uuid, ...location?.state } }) }} >
                                                            Physical Card{" "}
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </li>}
                                                    <li>
                                                        <a className="cursor-pointer" onClick={() => { navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards/add-card/${params?.card_name?.toLowerCase()}/virtual/create-card`, { state: { type: "virtual", card: location?.state?.card, uuid: location?.state?.uuid, ...location?.state } }) }}>
                                                            Virtual Card{" "}
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default AddCard