import React from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import endPoints from '../constants';
import { formatterNew } from '../hooks/dollarFormatter';
import { Bars } from 'react-loader-spinner';
import { useAuth } from '../components/AuthContext';
import Breadcrumb from '../components/Breadcrumb';
import SideDefault from '../components/SideDefault';
import TransactionTable from '../components/TransactionTable';
import profile from "../assets/images/no-user.svg"
import TransactionsHelper from '../hooks/TransactionsHelper.tsx';
import graph1 from '../assets/images/graph1.png';
import graph2 from '../assets/images/graph2.png';
import graph3 from '../assets/images/graph3.png';
import { formatCurrency } from '../hooks/dollarFormatter';
// import TransactionsHelper from '../hooks/TransactionsHelper';

const ViewAccount = () => {
    const { userRole } = useAuth();
    const params = useParams();
    const navigate = useNavigate();
    let page = 1;
    let per_page = 3;
    const { data: allAccountsData, dataLoading: allAccountsDataLoading } = useFetch(`${endPoints.ALL_ACCOUNTS}/${params?.acc_label?.split("_")[0]}`);
    const { data: contacts, dataLoading: contactDataLoading } = useFetch(`${endPoints.CONTACTS}?account_id=${params?.acc_label?.split("_")[0]}`);
    const { data: transaction, dataLoading: transactionDataLoading } = useFetch(`${endPoints.BANK_TRANSACTION}?page=${page}&per_page=${per_page}&account_id=${params?.acc_label?.split("_")[0]}`);
    const { data: ExternalAccount, dataLoading: loader } = useFetch(`/api/v3/ach_payments?type=incoming,external&account_id=${params?.acc_label?.split("_")[0]}`);

    const loading = () => {
        if (transactionDataLoading || contactDataLoading || allAccountsDataLoading) {
            return true
        }
        else return false
    }

    const showAccountDetails = () => {
        navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/account-details`, { state: { uuid: params?.acc_label?.split("_")[0], business_uuid: allAccountsData?.data?.attributes?.business_uuid } })
    }

    const handleCards = () => {
        navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards`, { state: { uuid: params?.acc_label?.split("_")[0], business_uuid: allAccountsData?.data?.attributes?.business_uuid } })
    }
    // breadcrumb routes
    const routes = [
        { name: 'Home', path: '/' },
        { name: `Business Accounts`, path: `/${params?.name}/accounts` },
        { name: `Account Details`, path: '' },
    ];

    const shortName = (name) => {
        name = name?.split(" ");
        // console.log("name");
        let res = name[0][0]?.toUpperCase()
        // let res = name?.length > 1 ? name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase() : name[0][0]?.toUpperCase()
        return res
    }

    function toCamelCase(str) {
        return str
            .toLowerCase()
            .replace(/\b\w/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match
            );
    }

    function formatDateWithWeekday(isoString) {
        const date = new Date(isoString);  // Convert string to Date object
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (zero-indexed)
        const day = String(date.getDate()).padStart(2, '0'); // Get day
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

        return `${month}/${day}/${year}`;
    }

    const acc_slug = (item) => {
        return item?.uuid + "_" + item?.bank_name?.trim()?.toLowerCase()?.replaceAll(' ', "-")
    }

    const balance = allAccountsData?.data?.attributes?.remaining_balance?.toString()?.split(".");
    return (
        <>
            <MainHeader />
            {!loading() && <div className="s-layout">
                <SideDefault />
                <main className="s-layout__content">
                    <div className="main-page">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card plan-list no-bg">
                                    {/* <div className="top-sec">
                                    <button onClick={() => navigate(-1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16">
                                            <path fill="currentColor" fill-rule="evenodd" d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8" clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                    <Breadcrumb routes={routes} />
                                    </div> */}
                                    <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                        <div className="me-auto pe-3 d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px", color: "38AFFF" }} width="30" height="40" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M15.71 12.71a6 6 0 1 0-7.42 0a10 10 0 0 0-6.22 8.18a1 1 0 0 0 2 .22a8 8 0 0 1 15.9 0a1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1a10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 1 1 4-4a4 4 0 0 1-4 4Z" />
                                            </svg>
                                            <h5 className="text-black fs-26 mb-0">Account Details</h5>
                                        </div>

                                        <div className="add-card">
                                            {/* <span>Account#{allAccountsData?.data?.attributes?.tp_account_number}</span> */}
                                            {/* <a className='cursor-pointer' onClick={handleCards}>
                                                <svg
                                                    width={23}
                                                    height={19}
                                                    viewBox="0 0 23 19"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_274_1471)">
                                                        <path
                                                            d="M1.81018 16.1337C1.29459 16.1337 0.864181 15.9557 0.518957 15.5997C0.172986 15.243 0 14.7988 0 14.2672V1.8201C0 1.28847 0.172986 0.844672 0.518957 0.48871C0.864181 0.131979 1.29459 -0.0463867 1.81018 -0.0463867H18.3653C18.8808 -0.0463867 19.3113 0.131979 19.6565 0.48871C20.0025 0.844672 20.1754 1.28847 20.1754 1.8201V7.10982H1.12086V14.2672C1.12086 14.4444 1.19259 14.6073 1.33606 14.756C1.48028 14.904 1.63832 14.9779 1.81018 14.9779H11.4238V16.1337H1.81018ZM1.12086 4.35459H19.0546V1.8201C19.0546 1.64289 18.9828 1.47993 18.8394 1.33123C18.6952 1.1833 18.5371 1.10933 18.3653 1.10933H1.81018C1.63832 1.10933 1.48028 1.1833 1.33606 1.33123C1.19259 1.47993 1.12086 1.64289 1.12086 1.8201V4.35459ZM18.4942 19.023V15.5558H15.1316V14.4001H18.4942V10.9329H19.615V14.4001H22.9776V15.5558H19.615V19.023H18.4942Z"
                                                            fill="black"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_274_1471">
                                                            <rect width={23} height={19} fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                View Cards
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="card-body busi-account pt-2">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <div className="add-acts">

                                                            <div className="lists">
                                                                <span className="frst text-capitalize  cursor-pointer" onClick={() => { showAccountDetails() }}>
                                                                    {/* <small>{allAccountsData?.data?.attributes?.label}</small> */}
                                                                    <h5><span>Account Balance</span>
                                                                        {/* <span>Account#{allAccountsData?.data?.attributes?.tp_account_number}</span> */}
                                                                    </h5>

                                                                </span>

                                                                <span className="frst text-capitalize  cursor-pointer gross" onClick={() => { showAccountDetails() }}>
                                                                    {/* <small>{allAccountsData?.data?.attributes?.label}</small> */}

                                                                    {allAccountsData?.data?.attributes?.remaining_balance != null ? (
                                                                        <div className='ttl-blnc '>
                                                                            <span></span>
                                                                                <span>
                                                                                    {(formatCurrency(balance[0]) || '0').slice(0, -3)}
                                                                                    .{balance[1]?.toString().padStart(2, '0') || '00'}
                                                                                </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='ttl-blnc'>
                                                                            <span>$</span>
                                                                            <span>0</span>
                                                                            <small>.00</small>
                                                                        </div>
                                                                    )}
                                                                    <h5 className='ttls'><span>Pending Deposits: <strong>{allAccountsData?.data?.attributes?.pending_bal}</strong> </span></h5>
                                                                </span>
                                                                <div className="fund-bts">
                                                                    <a href="javaScipt:void(0)" onClick={() => { navigate(`/move-funds/${params?.name?.split("_")[0]}/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0], acc_name: allAccountsData?.data?.attributes?.label, acc_num: allAccountsData?.data?.attributes?.tp_account_number, details: { bus_id: params?.name, acn_id: params?.acc_label } } }) }}>Send Funds <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 14 14">
                                                                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M.5 7h13M10 10.5L13.5 7L10 3.5" />
                                                                    </svg></a>
                                                                    <a href="javaScipt:void(0)" onClick={() => { navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/fund-this-account`, { state: { uuid: params?.acc_label?.split("_")[0] } }) }}>Receive Funds <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 14 14"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path d="M.5 9.5h9a4 4 0 1 0 0-8h-3" /><path d="m3.5 6.5l-3 3l3 3" /></g></svg></a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="add-acts add-ext-acc">
                                                            <div className="lists">

                                                                {userRole != "view_account" &&
                                                                    <div className="col-xl-12 pt-2">
                                                                        <h5><span>External Accounts</span>
                                                                            <a className='view-all-links' onClick={() => { navigate(`/external-account`) }}>View All</a></h5>
                                                                        <div className="row tab-links">
                                                                            <div className="col-md-12">
                                                                                <ul className="ext-act">
                                                                                    {ExternalAccount?.data?.map((item, index) => {
                                                                                        if (index < 2)
                                                                                            return (
                                                                                                <li key={index} className='cursor-pointer'>
                                                                                                    <a onClick={() => { navigate(`/relink-external-account/${acc_slug(item?.attributes)}`, { state: item }) }}> {item.attributes.label}
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                                                                                                            <path fill="currentColor" d="m359.873 121.377l-22.627 22.627l95.997 95.997H16v32.001h417.24l-95.994 95.994l22.627 22.627L494.498 256L359.873 121.377z" />
                                                                                                        </svg>
                                                                                                    </a>
                                                                                                </li>
                                                                                            )
                                                                                    })}

                                                                                    {/* <li className='cursor-pointer'>
                                                                                        <a href="#"> Primary
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                                                                                                <path fill="currentColor" d="m359.873 121.377l-22.627 22.627l95.997 95.997H16v32.001h417.24l-95.994 95.994l22.627 22.627L494.498 256L359.873 121.377z" />
                                                                                            </svg>
                                                                                        </a>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">

                                                        <div className="add-acts">
                                                            <div className="lists">
                                                                <span className="frst text-capitalize">
                                                                    <h5><span>Contact</span>
                                                                        <a className='view-all-links' onClick={() => { navigate(`/view-contacts/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0], } }) }}>View All</a></h5>
                                                                    <ul className="tab-links stf-list">
                                                                        {contacts?.data && contacts?.data?.map((item, id) => {
                                                                            if (id < 7)
                                                                                return (
                                                                                    <li className='cursor-pointer' key={id} onClick={() => { navigate(`/contact-details/${params?.acc_label?.split("_")[0]}/${item?.attributes?.uuid}`, { state: { contactId: item?.attributes?.uuid, uuid: params?.acc_label?.split("_")[0], details: item } }) }}>
                                                                                        <Link >{item?.attributes?.first_name} {item?.attributes?.last_name}
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                                                                                                <path fill="currentColor" d="m359.873 121.377l-22.627 22.627l95.997 95.997H16v32.001h417.24l-95.994 95.994l22.627 22.627L494.498 256L359.873 121.377z" />
                                                                                            </svg>
                                                                                        </Link>
                                                                                    </li>
                                                                                )
                                                                        })}
                                                                    </ul>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className='transaction-main'>
                                                            <h5><span>Transactions</span>
                                                                <a className='view-all-links' onClick={() => { navigate(`/card-transaction/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0], business_uuid: allAccountsData?.data?.attributes?.business_uuid, accountId: allAccountsData?.data?.id } }) }}>View All</a></h5>
                                                            <table >
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Date</th>
                                                                    <th>Type</th>
                                                                    <th>Amount</th>
                                                                    <th></th>
                                                                </tr>
                                                                {transaction?.data?.data && transaction?.data?.data?.map((item, id) => (
                                                                    <tr key={id}>
                                                                        <td>
                                                                            {TransactionsHelper.getLabel(item, allAccountsData?.data?.id) != "No Name" ?
                                                                                TransactionsHelper.getLabel(item, allAccountsData?.data?.id)
                                                                                : item?.attributes?.description}
                                                                        </td>
                                                                        <td>{formatDateWithWeekday(item?.attributes?.created_at)}</td>
                                                                        <td>{item?.attributes?.txn_type}</td>
                                                                        {item?.attributes?.debitCredit == "debit" ? <td className='loss'>-${Number(item?.attributes?.amount || 0).toFixed(2)}</td> : <td className='profit'>+${Number(item?.attributes?.amount || 0).toFixed(2)}</td>}
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <button>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                                        <path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                {/*  <tr>
                                                        <td>Peyton Owens</td>
                                                        <td>Tuesday, 08 December 2024</td>
                                                        <td>+$17,500.00</td>
                                                        <td>  <div className="dropdown">
                                                            <button>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-content">
                                                                <a href="#" onclick="editAction()">Edit</a>
                                                                <a href="#" onclick="deleteAction()">Delete</a>
                                                            </div>
                                                        </div></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Peyton Owens</td>
                                                        <td>Tuesday, 08 December 2024</td>
                                                        <td className='loss'>-$3,714.00</td>
                                                        <td>  <div className="dropdown">
                                                            <button>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-content">
                                                                <a href="#" onclick="editAction()">Edit</a>
                                                                <a href="#" onclick="deleteAction()">Delete</a>
                                                            </div>
                                                        </div></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Noah Calvert</td>
                                                        <td>Tuesday, 08 December 2024</td>
                                                        <td>+$17,500.00</td>
                                                        <td>  <div className="dropdown">
                                                            <button>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-content">
                                                                <a href="#" onclick="editAction()">Edit</a>
                                                                <a href="#" onclick="deleteAction()">Delete</a>
                                                            </div>
                                                        </div></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Peyton Owens</td>
                                                        <td>Tuesday, 08 December 2024</td>
                                                        <td className='loss'>-$3,714.00</td>
                                                        <td>  <div className="dropdown">
                                                            <button>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-content">
                                                                <a href="#" onclick="editAction()">Edit</a>
                                                                <a href="#" onclick="deleteAction()">Delete</a>
                                                            </div>
                                                        </div></td>
                                                    </tr> */}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="right-gph">

                                                    <h2>
                                                        <span>Total Income <small>August 2024</small></span>
                                                        <div className="dropdown">
                                                            <button>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </h2>
                                                    <strong>2,258,58.25 <span className="grow"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                                                        <path fill="currentColor" d="M20 8v2h6.586L18 18.586l-4.293-4.293a1 1 0 0 0-1.414 0L2 24.586L3.414 26L13 16.414l4.293 4.293a1 1 0 0 0 1.414 0L28 11.414V18h2V8Z" />
                                                    </svg> 20%</span></strong>
                                                    <img src={graph2} alt="Description" />
                                                </div>
                                                <div className="right-gph">

                                                    <h2>
                                                        <span>Total Income <small>August 2024</small></span>
                                                        <div className="dropdown">
                                                            <button>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </h2>
                                                    <strong>2,258,58.25 <span className="loss"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                                                        <path fill="currentColor" d="M20 8v2h6.586L18 18.586l-4.293-4.293a1 1 0 0 0-1.414 0L2 24.586L3.414 26L13 16.414l4.293 4.293a1 1 0 0 0 1.414 0L28 11.414V18h2V8Z" />
                                                    </svg> 20%</span></strong>
                                                    <img src={graph1} alt="Description" />
                                                </div>
                                                <div className="right-gph">

                                                    <h2>
                                                        <span>Total Income <small>August 2024</small></span>
                                                        <div className="dropdown">
                                                            <button>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </h2>
                                                    <strong>2,258,58.25 <span className="grow"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                                                        <path fill="currentColor" d="M20 8v2h6.586L18 18.586l-4.293-4.293a1 1 0 0 0-1.414 0L2 24.586L3.414 26L13 16.414l4.293 4.293a1 1 0 0 0 1.414 0L28 11.414V18h2V8Z" />
                                                    </svg> 20%</span></strong>
                                                    <div className='bottom-rw'>
                                                        <img src={graph3} alt="Description" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        {/* <div className="col-xl-12  mt-4">
                                            <div className="align-items-center">
                                                <div className="main-trans bg-white">
                                                    <h4>Transactions</h4>
                                                    
                                                    {transaction?.data?.data && transaction?.data?.data?.map((item, id) => {
                                                        return (
                                                            <TransactionTable item={item} accountId={allAccountsData?.data?.id} key={id} />
                                                        )
                                                    })
                                                    }
                                                    <a className='view-all-link' onClick={() => { navigate(`/card-transaction/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0], business_uuid: allAccountsData?.data?.attributes?.business_uuid, accountId: allAccountsData?.data?.id } }) }}>
                                                        View All
                                                    </a>
                                                </div>
                                               
                                            </div>
                                            
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div >}

            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading()}
            />
        </>
    )
}

export default ViewAccount