import axios from 'axios';
import React, { useState } from 'react';
import { Bars } from 'react-loader-spinner';
import PhoneInput from 'react-phone-number-input/input';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleMapsComponent from '../components/GoogleMapsComponent';
import SideDefault from '../components/SideDefault';
import endPoints from '../constants';
import MainHeader from '../components/MainHeader';

const ACH = () => {
    const location = useLocation();
    const params = useParams();
    const paymentDtl = location.state;
    const navigate = useNavigate();

    const user = localStorage.getItem("skyfi");
    const tk = JSON.parse(user);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [achAddress, setACHAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        regionCode: "",
        zipCode: "",
    });
    const [errors, setErrors] = useState({});

    const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const handleSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;

            const newAddress = {
                address1: address,
                address2: '',
                city: '',
                country: '',
                state: '',
                zipCode: ''
            };

            addressComponents.forEach(component => {
                const types = component.types;
                if (types.includes('locality')) {
                    newAddress.city = component.long_name;
                }
                if (types.includes("country")) {
                    newAddress.country = component.short_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.state = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode = component.long_name;
                }
            });

            setACHAddress(newAddress);
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
        setACHAddress({ ...achAddress, [name]: value });
        if (name === "email") {
            if (value && !isValidEmail(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Invalid email format"
                }));
                return;
            }
        }
        setErrors({ ...errors, [name]: "" });
    };

    const handlePhoneChange = (value) => {
        if (value && value?.length != 12) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: "Phone number should be 10 digits"
            }));
            return;
        }
        else {
            setData({ ...data, phone: value });
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            phone: ""
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};
        
        if (!data.legal_bussiness_name) validationErrors.legal_bussiness_name = "Business Name is required";
        if (!data.acc_holder_name) validationErrors.acc_holder_name = "Account Holder Number is required";
        if (!achAddress.city) validationErrors.city = "City is required";
        if (!achAddress.state) validationErrors.regionCode = "state is required";
        if (!achAddress.country) validationErrors.country = "Country is required";
        if (!achAddress.zipCode) validationErrors.zipCode = "ZipCode is required";
        if (!data.phone) validationErrors.phone = "Phone is required";
        if (!data.email) validationErrors.email = "Email is required";

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        let resultData = {
            account_id: paymentDtl?.account_id,
            user_id: paymentDtl?.user_id,
            amount: paymentDtl?.amount,
            speed: paymentDtl?.speed,
            source: {
                account_reference_id: paymentDtl?.source?.account_reference_id
            },
            destination: {
                account_number: paymentDtl?.destination?.account_number,
                routing_number: paymentDtl?.destination?.routing_number,
                account_type: paymentDtl?.destination?.account_type,
                account_holder_name: paymentDtl?.destination?.account_holder_name,
                metadata: {}
            },
            legal_name: data.legal_bussiness_name,
            account_holder_name: data.acc_holder_name,
            address1: achAddress.address1,
            city: achAddress.city,
            state: achAddress.state,
            country: achAddress.country,
            zip5: achAddress.zipCode,
            phone: data.phone?.slice(2),
            email: data.email,
            metadata: {}
        }

        try {
            setLoading(true);

            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MAKE_PAY_VIA_ACH}`, resultData,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            
            setLoading(false);
            if (resp.status === 200 || resp.status === 202) {
                toast.success(resp?.data?.message);
                navigate(`/view-contacts/${params?.acc_id?.split("_")[0]}`, { replace: true });
            }else {
                toast.error(resp?.data?.message);
            }
        } catch (error) {
            setLoading(false);
            console.log("error", error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }
  return (
      <>
      <MainHeader />
          {!loading && <div className="s-layout">
              <SideDefault />
              <main className="s-layout__content">
                  <div className="main-page">
                      <div className="row justify-content-center h-100 align-items-center">
                          <div className="col-lg-8 col-md-10">
                              <div className="card plan-list account-eligibility">
                                  <div className="authincation-content">
                                      <div className="row no-gutters">
                                          <div className="col-xl-12">
                                              <div className="auth-form">
                                                  <h4 className="mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                                                      <path fill="currentColor" d="M247.759 14.358L16 125.946V184h480v-58.362ZM464 152H48v-5.946l200.241-96.412L464 146.362ZM48 408h416v32H48zm-32 56h480v32H16zm40-248h32v160H56zm368 0h32v160h-32zm-96 0h32v160h-32zm-176 0h32v160h-32zm88 0h32v160h-32z" />
                                                  </svg> ACH</h4>
                                                  <form onSubmit={handleSubmit} className="mt-4">
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">Legal Business Name</label>
                                                          <input
                                                              onChange={handleChange}
                                                              value={data?.legal_bussiness_name}
                                                              name='legal_bussiness_name'
                                                              type="text"
                                                              className={`form-control`}
                                                              placeholder="Name"
                                                          />
                                                          {errors.legal_bussiness_name && <div className="text-danger">{errors.legal_bussiness_name}</div>}
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">Account Holder Name</label>
                                                          <input
                                                              onChange={handleChange}
                                                              value={data?.acc_holder_name}
                                                              name='acc_holder_name'
                                                              type="text"
                                                              className={`form-control`}
                                                              placeholder="Account Holder Number"
                                                          />
                                                          {errors.acc_holder_name && <div className="text-danger">{errors.acc_holder_name}</div>}
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">Address 1</label>
                                                          <GoogleMapsComponent 
                                                            value={achAddress.address1}
                                                            onChange={(value) => {
                                                                setACHAddress((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        address1: value
                                                                    }
                                                                })
                                                            }}
                                                            onSelect={handleSelect}
                                                            errorText={errors.address1}
                                                          />
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">Address 2</label>
                                                          <textarea
                                                              className="form-control"
                                                              rows={2}
                                                              placeholder="Enter Address 2"
                                                              spellCheck="false"
                                                              name="address2"
                                                              value={achAddress.address2}
                                                              onChange={handleChange}
                                                          />
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">City</label>
                                                          <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Enter City"
                                                              name="city"
                                                              value={achAddress.city}
                                                              onChange={handleChange}

                                                          />
                                                          {errors.city && <small className="text-danger">{errors.city}</small>}
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">State</label>
                                                          <input
                                                              type="text"
                                                              name="state"
                                                              className="form-control"
                                                              placeholder="Enter state"
                                                              value={achAddress.state}
                                                              onChange={handleChange}
                                                          />
                                                          {errors.state && <small className="text-danger">{errors.state}</small>}
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">Country</label>
                                                          <input
                                                              type="text"
                                                              name="country"
                                                              className="form-control"
                                                              placeholder="Enter Country"
                                                              value={achAddress.country}
                                                              onChange={handleChange}
                                                          />
                                                          {errors.country && <small className="text-danger">{errors.country}</small>}
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">Zip Code</label>
                                                          <input
                                                              type="text"
                                                              name="zipCode"
                                                              className="form-control"
                                                              placeholder="Enter Zip Code"
                                                              value={achAddress.zipCode}
                                                              onChange={handleChange}
                                                          />
                                                          {errors.zipCode && <small className="text-danger">{errors.zipCode}</small>}
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">Phone</label>
                                                          <PhoneInput
                                                              country="US"
                                                              maxLength={14}
                                                              className="form-control"
                                                              placeholder="Phone Number"
                                                              value={data?.phone}
                                                              onChange={(val) => { handlePhoneChange(val) }} />
                                                          {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                                      </div>
                                                      <div className="form-group">
                                                          <label className="mb-1 form-label">Email</label>
                                                          <input
                                                              onChange={handleChange}
                                                              value={data?.email}
                                                              name='email'
                                                              type="text"
                                                              className={`form-control`}
                                                              placeholder="Email"
                                                          />
                                                          {errors.email && <div className="text-danger">{errors.email}</div>}
                                                      </div>
                                                      <div className="create-account">
                                                          <button type="submit" className="btn">
                                                              {loading ? "Loading..." : "Submit"}
                                                          </button>
                                                      </div>
                                                  </form>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </main>
          </div>}
          <Bars
              height="80"
              width="80"
              color="#39AFFF"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass="bars"
              visible={loading}
          />
      </>
  )
}

export default ACH
