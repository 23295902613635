import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import PhoneInput from 'react-phone-number-input/input'
import SideDefault from '../components/SideDefault';
import MainHeader from '../components/MainHeader';

const UpdateContact = () => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const { data: contacts, dataLoading: contactDataLoading } = useFetch(`${endPoints.CONTACTS}?account_id=${params?.acc_id}`);

    const [cntData, setCntData] = useState({
        account_id: "",
        phone: "",
        email: "",
        last_name: "",
        first_name: "",
        routing_number: "",
        account_number: "",
    });

    useEffect(() => {
        if (contacts) {
            let findContact = contacts?.data?.find((item) => {
                if (item?.attributes?.uuid == params?.contact_id) {
                    return item
                }
            });

            if (findContact) {

                setCntData({
                    ...cntData,
                    account_id: findContact?.attributes?.account_id,
                    account_number: findContact?.attributes?.account_number,
                    phone: `+1${findContact?.attributes?.phone}`,
                    email: findContact?.attributes?.email,
                    last_name: findContact?.attributes?.last_name,
                    first_name: findContact?.attributes?.first_name,
                    routing_number: findContact?.attributes?.routing_number,
                })
            }
        }
    }, [location?.state, contacts])
    const [errors, setErrors] = useState({});
    const isAlpha = (value) => /^[A-Za-z\s]*$/.test(value);
    const isNumeric = (value) => /^\d+$/.test(value);
    const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const handleChange = (name, value) => {

        setCntData({ ...cntData, [name]: value });
        if ((name === "first_name" || name === "last_name") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
            return;
        }

        if (name === "phone") {
            // if (!isNumeric(value)) {
            //     setErrors((prevErrors) => ({
            //         ...prevErrors,
            //         phone: "Only numbers are allowed"
            //     }));
            //     return;
            // }
            if (value?.length != 12) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: "Phone number should be 10 digits"

                }));
                return;
            }
        }
        if ((name === "account_number") && value?.length != 16) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid account number"
            }));
            return;
        }
        if ((name === "routing_number") && value?.length != 9) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid routing number"
            }));
            return;
        }
        if (name === "email" && !isValidEmail(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Invalid email format"
            }));
            return;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ""
        }));

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/update-contact-address/${params?.acc_id}/${params?.contact_id}`, { state: { data: cntData, details: location?.state?.details, uuid: location?.state?.uuid } });
    };

    return (
        <>
            <MainHeader />
            <div className="s-layout">
                <SideDefault />
                <main className="s-layout__content">
                    <div className="main-page">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-lg-8 col-md-10">
                                <div className="card login-part">
                                    <div className="authincation-content">
                                        <div className="row no-gutters">
                                            <div className="col-xl-12">
                                                <div className="auth-form">
                                                    <h4 className="mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
              <path fill="currentColor" d="M9.993 10.573a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078c.047.367-.226.7-.61.745c-.383.045-.733-.215-.78-.582c-.54-4.19-4.169-7.345-8.57-7.345c-4.425 0-8.101 3.161-8.64 7.345c-.047.367-.397.627-.78.582c-.384-.045-.657-.378-.61-.745c.496-3.844 3.281-6.948 6.975-8.068A6 6 0 0 1 10 0Z" />
            </svg> Update Contact</h4>
                                                    <form className="mt-4" onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">First Name or Business Name</label>
                                                            <input
                                                                value={cntData?.first_name}
                                                                onChange={(e) => handleChange("first_name", e.target.value)}
                                                                name='first_name'
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="First Name or Business Name"
                                                            />
                                                            {errors.first_name && <small className="text-danger error">{errors.first_name}</small>}

                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Last Name</label>
                                                            <input
                                                                value={cntData?.last_name}
                                                                onChange={(e) => handleChange("last_name", e.target.value)}
                                                                name='last_name'
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Last Name"
                                                            />
                                                            {errors.last_name && <small className="text-danger error">{errors.last_name}</small>}

                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Mobile number</label>
                                                            {/* <input
                                                                    onChange={(e) => handleChange("number", e.target.value)}
                                                                    name='phone'
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Mobile number"
                                                                    /> */}

                                                            <PhoneInput
                                                                value={cntData?.phone}
                                                                placeholder="Mobile number"
                                                                country="US"
                                                                className="form-control"
                                                                // error={cntData?.phone ? (isValidPhoneNumber(cntData?.phone) ? undefined : 'Invalid phone number') : 'Phone number required'} 
                                                                onChange={(e) => { handleChange("phone", e) }} />
                                                            {errors.phone && <small className="text-danger error">{errors.phone}</small>}

                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Email</label>
                                                            <input
                                                                value={cntData?.email}

                                                                onChange={(e) => handleChange("email", e.target.value)}
                                                                name='email'
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Email"
                                                            />
                                                            {errors.email && <small className="text-danger error">{errors.email}</small>}

                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Account Number</label>
                                                            <input
                                                                value={cntData?.account_number}

                                                                onChange={(e) => handleChange("account_number", e.target.value)}
                                                                name='account_number'
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Account Number"
                                                            />
                                                            {errors.account_number && <small className="text-danger error">{errors.account_number}</small>}

                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Routing Number</label>
                                                            <input
                                                                value={cntData?.routing_number}

                                                                onChange={(e) => handleChange("routing_number", e.target.value)}
                                                                name='routing_number'
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Routing Number"
                                                            />
                                                            {errors.routing_number && <small className="text-danger error">{errors.routing_number}</small>}

                                                        </div>
                                                        <div className="create-account mt-5" >
                                                            <button>Update</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default UpdateContact;
