import React, { useEffect, useMemo, useState } from 'react';
import countries from "i18n-iso-countries";
import SimpleHeader from '../components/SimpleHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { toast } from 'react-toastify';
// import PhoneInput from "react-phone-number-input/input";
import { useCountries } from 'use-react-countries'
import PhoneInput, { parsePhoneNumber } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const RegisterPersonalDetail = () => {
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const [fetch, setFetch] = useState(0);
    const [cont, setCont] = useState(false);
    const { countries: con } = useCountries();
    const location = useLocation();
    useEffect(() => {
        const user = localStorage.getItem("skyfi");
        const detail = localStorage.getItem("user");
        const role = localStorage.getItem("skyrole");
        if (user && detail && role) {
            navigate("/");
        }
        if (!location?.state?.email && !location?.state?.id) {
            setFetch(fetch + 1);
        }
    }, []);
    const { data: me, dataLoading: meLoading } = useFetch(`${endPoints.ME}`, fetch);

    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dob: "",
        ssn: "",
        userData: "",
        countryCode: "",
    });
    console.log(userData);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    // console.log(me);
    useEffect(() => {
        if (me || location?.state?.email) {
            setUserData({
                ...userData, email: me?.email ?? location?.state?.email
            })
        }
    }, [me])
    const isAlpha = (value) => /^[a-zA-Z]+$/.test(value);
    const isNumeric = (value) => /^\d+$/.test(value);

    const handleChange = (name, value) => {
        console.log(name, value);

        setUserData({
            ...userData,
            [name]: value
        });
        if ((name === "firstName" || name === "lastName") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed",
            }));
            return;
        }

        // if (name === "phone") {
        // if (value?.length != 12) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         phone: "Phone number should be 10 digits"

        //     }));
        //     return;
        // }
        // }
        // if (name === "ssn" && !isNumeric(value)) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         ssn: "SSN should be numbers only"

        //     }));
        //     return;
        // }
        // else
        if (name === "ssn") {
            if (value?.length != 9) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    ssn: "SSN should be 9 digits"

                }));
                return;
            }
        }
        // Remove error message for the specific field
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ""
            });
        }
    };

    const validate = () => {
        let validationErrors = { ...errors };
        if (!userData.firstName) {
            validationErrors.firstName = "First Name is required";
        }

        if (!userData.lastName) {
            validationErrors.lastName = "Last Name is required";
        }

        if (!userData.email) {
            validationErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
            validationErrors.email = "Email address is invalid";
        }

        if (!userData.phone) {
            validationErrors.phone = "Phone is required";
        } else if (userData?.phone?.length != 10) {
            validationErrors.phone = "Phone number is invalid";
        }

        if (!userData.dob) {
            validationErrors.dob = "Date of Birth is required";
        }

        if (!userData.ssn) {
            validationErrors.ssn = "SSN is required";
        }
        else if (!/^\d{4}$/.test(userData.ssn) && !/^\d{9}$/.test(userData.ssn)) {
            validationErrors.ssn = "SSN should be 9 digits";
        }
        Object.keys(validationErrors).forEach(key => {
            if (validationErrors[key] === '') {
                delete validationErrors[key];
            }
        });
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return
        }
        try {
            let data = {
                ...userData, phone: userData?.phone, countries : countries.getAlpha2Code(userData?.countries, "en") || "N/A",
            }
            navigate('/register-personal-address', { state: { userData: data, id: me?.id ?? location?.state?.id } })
        } catch (error) {
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
            toast.error(error?.response?.data?.error[0]);
        }
    };

    const formatSSN = (numericValue) => {
        const match = numericValue.match(/^(\d{3})(\d{2})(\d{4})$/);
        if (match) {
            let formattedValue = match[1]; // AAA
            if (match[2]) {
                formattedValue += '-' + match[2]; // GG
            }
            if (match[3]) {
                formattedValue += '-' + match[3]; // SSSS
            }
            return formattedValue;
        }
        return numericValue;
    };

    const handleCheckCitizen = (val) => {
        console.log("val", val);
        setCont(val);
        if (val) {
            setUserData((prev) => ({
                ...prev,
                countries: "United States",
            }));
        } else {
            setUserData((prev) => ({
                ...prev,
                countries: "",
            }));
        }
    }
    const handleChangePhone = (value, data) => {
        const countryCode = data.dialCode; // Get the country calling code
        const phoneNumber = value.replace(`${countryCode}`, ''); //  Remove the country code from the number
        console.log("phoneNumber.countryCode", countryCode);
        if (phoneNumber && phoneNumber?.length != 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: "Phone number should be 10 digits"

            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: ""
            }));
            setUserData((prev) => ({
                ...prev,
                phone: phoneNumber,
                countryCode
            }));
        }
    }
    // console.log("countries", countries);

    const currentDate = new Date().toISOString().split('T')[0];
    return (
        <>
            {/* <SimpleHeader /> */}
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-xl-11 col-xxl-10 m-auto main-page">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-lg-8 col-md-10">
                                <div className="cardsignup">
                                    <div className="authincation-content">
                                        <div className="row no-gutters">
                                            <div className="col-xl-12">
                                                <div className="auth-form">
                                                    <h4 className="mb-4">Sign up for your account </h4>
                                                    <form className="mt-4" onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">First Name</label>
                                                            <input
                                                                type="text"
                                                                name="firstName"
                                                                className="form-control"
                                                                placeholder="First Name"
                                                                value={userData.firstName}
                                                                onChange={(e) => handleChange("firstName", e.target.value)}
                                                            />
                                                            {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Last Name</label>
                                                            <input
                                                                type="text"
                                                                name="lastName"
                                                                className="form-control"
                                                                placeholder="Last Name"
                                                                value={userData.lastName}
                                                                onChange={(e) => handleChange("lastName", e.target.value)}
                                                            />
                                                            {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Email</label>
                                                            <input
                                                                type="text"
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Enter Email"
                                                                value={userData.email}
                                                                disabled
                                                            />
                                                            {errors.email && <p className="text-danger">{errors.email}</p>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Phone</label>
                                                            {/* <input
                                                                type="text"
                                                                name="phone"
                                                                className="form-control"
                                                                placeholder="Enter Phone"
                                                                value={userData.phone}
                                                                onChange={handleChange}
                                                            /> */}
                                                            <PhoneInput
                                                                country={'us'}
                                                                // value={userData?.phone || ''}
                                                                enableTerritories={true}
                                                                placeholder="Mobile number"
                                                                inputClass="form-control"
                                                                onChange={handleChangePhone}
                                                                international={true} // Enables international format (+1, +91, etc.)
                                                            // preferredCountries={['us', 'in']} // Optional: To show preferred countries
                                                            />
                                                            {errors.phone && <p className="text-danger">{errors.phone}</p>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">DOB</label>
                                                            <input
                                                                max={currentDate}
                                                                type="date"
                                                                name="dob"
                                                                className="form-control"
                                                                placeholder="DOB"
                                                                value={userData.dob}
                                                                onChange={(e) => handleChange("dob", e.target.value)}
                                                            />
                                                            {errors.dob && <p className="text-danger">{errors.dob}</p>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">SSN</label>
                                                            <input
                                                                type="text"
                                                                name="ssn"
                                                                maxLength={11}
                                                                className="form-control"
                                                                placeholder="Enter SSN"
                                                                // value={userData.ssn}
                                                                value={formatSSN(userData.ssn)}
                                                                // handleBlur={formatSSN(userData.ssn)}
                                                                onChange={(e) => handleChange("ssn", e.target.value)}
                                                                onInput={(e) => {
                                                                    if (e.target.value.length > 11) {
                                                                        e.target.value = e.target.value.slice(0, 11); // Trim to 11 characters if necessary
                                                                    }
                                                                }}
                                                            />
                                                            {errors.ssn && <p className="text-danger">{errors.ssn}</p>}
                                                        </div>
                                                        <div className="form-group">
                                                            <input type='checkbox' id='isPrimary' onChange={(e) => handleCheckCitizen(e.target.checked)} />
                                                            <label htmlFor='isPrimary' className='fs-14 p-1' >
                                                                Are you a US citizen?
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Citizenship</label>
                                                            <select
                                                                name='countries'
                                                                onChange={(e) => handleChange("countries", e.target.value)}
                                                                value={userData?.countries}
                                                                disabled={cont}
                                                                className={`form-control `}
                                                                aria-label="Default select example"
                                                            >
                                                                <option selected="">Select country</option>
                                                                {con?.map((item) => (
                                                                    <option value={item.name}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                            {errors.account_type && <div className="text-danger">{errors.account_type}</div>}
                                                        </div>
                                                        <div className="text-center">
                                                            <button type="submit" className="btn">
                                                                Next
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisterPersonalDetail;
