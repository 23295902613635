import React, { useEffect, useState } from "react";
import MainHeader from "../components/MainHeader";
import useFetch from "../hooks/useFetch";
import endPoints from "../constants";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import Sidebar from "../components/Sidebar";
import SideDefault from "../components/SideDefault";

const CreateCard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [cardData, setCardData] = useState({
        account_id: params?.acc_label?.split("_")[0],
        staff_id: "",
        label: "",
        brand: params?.card_name,
    });
    const [labelError, setLabelError] = useState(false);
    const { data: myDetails, dataLoading: myDetailsLoading } = useFetch(`${endPoints.ME}`);

    const { data: staff, dataLoading: staffLoading } = useFetch(
        `${endPoints.STAFFS}?role_ids=1,2,4,5`
        // `${endPoints.STAFFS}?role_ids=1,2,4,5&business_id=${params?.name?.split("_")[0]}`

    );

    const loading = () => {
        if (myDetailsLoading || staffLoading) {
            return true
        }
        else return false
    }
    const [staffData, setStaffData] = useState([]);
    let listdata = [
        {
            attributes: {
                first_name: "Myself",
                last_name: "",
                uuid: "Myself",
            },

            id: "Myself",
        },
    ];
    let listDataForAdmin = [
        {
            attributes: {
                first_name: "Myself",
                last_name: "",
                uuid: "Myself",
            },

            id: "Myself",
        },

        {
            attributes: {
                first_name: "Owner",
                last_name: "",
                uuid: "Owner",
            },

            id: "Owner",
        },
    ];
    const cardFor = myDetails?.person ? listDataForAdmin : listdata;
    useEffect(() => {
        if (params?.card_name == "mastercard" && params?.card_type == "physical"){
            toast.error("Can't create Physical card with Mastercard");
            navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards`);
            return;
        }
        if (staff?.data?.length) {
            const newArray = staff?.data.filter(item => {
                return item.attributes.permission_accounts === null || item.attributes.permission_accounts.length === 0 || item.attributes.permission_accounts.includes(myDetails?.uuid?.toString());
            });
            const result = [...cardFor, ...newArray]
            setStaffData(result)
            // console.log("newArray",newArray);
        }
        // alert(staff.length)
    }, [staff, myDetails])
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (cardData.label.trim() == "") {
            setLabelError(true);
            return
        }
        try {
            setSubmitLoading(true);
            let data = {
                account_id: params?.acc_label?.split("_")[0],
                staff_id: cardData?.staff_id == "Myself" ? null : cardData?.staff_id == "Owner" ? myDetails?.uuid : cardData?.staff_id,
                label: cardData?.label,
                brand: params?.card_name,
            }
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            let url = params?.card_type == "physical" ? endPoints.CREATE_PHYSICAL_CARD : endPoints.CREATE_VIRTUAL_CARD;
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${url}`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            setSubmitLoading(false);

            if (resp?.status == 200) {
                toast.success("Card Created");
                navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards`);
            }
        } catch (error) {
            setSubmitLoading(false);
            console.log(error);
            // toast.error(error?.response?.data?.error[0]?.errorDesc);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    };

    return (
        <>
            <MainHeader />

            {!loading() && <div className="s-layout">
                <SideDefault />
                <main className="s-layout__content">
                    <div className="main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-3 text-capitalize" >
                                                    Create {location?.state?.type} {location?.state?.card} Card
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <form onSubmit={handleSubmit}>
                                                        <ul className="physical-card tab-links">
                                                            <li>
                                                                <h5>Card Label</h5>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="First Name or Business Name"
                                                                    onChange={(e) => { setCardData({ ...cardData, label: e.target.value }); setLabelError(false); }}
                                                                />
                                                                {labelError && <small className="text-danger">Required</small>}
                                                            </li>
                                                            <li className="mt-5">
                                                                <h5>Card For</h5>
                                                                <span className="arrow">
                                                                    <svg
                                                                        width={17}
                                                                        height={9}
                                                                        viewBox="0 0 17 9"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M16 1.38773L9.52015 7.87123C9.42084 8.01319 9.3026 8.12589 9.17226 8.20281C9.04191 8.27973 8.90207 8.31934 8.76082 8.31934C8.61958 8.31934 8.47973 8.27973 8.34939 8.20281C8.21905 8.12589 8.1008 8.01319 8.00149 7.87123L1.52025 1.38773"
                                                                            stroke="black"
                                                                            strokeWidth="1.30203"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <select
                                                                    className="form-control"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => { setCardData({ ...cardData, staff_id: e.target.value }) }}
                                                                >
                                                                    <option selected="">Select Role </option>
                                                                    {staffData && staffData?.map((item, id) => {
                                                                        return (
                                                                            <option key={id} value={item?.attributes?.uuid}>{item?.attributes?.first_name} {item?.attributes?.last_name}</option>

                                                                        )
                                                                    })}

                                                                </select>
                                                            </li>
                                                        </ul>
                                                        <div className="create-account mt-5">
                                                            <button type="submit" className="btn" disabled={submitLoading}>
                                                                {submitLoading ? "Creating..." : "Submit"}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </main>
            </div>}

            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading()}
            />
        </>
    );
};

export default CreateCard;
