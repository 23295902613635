import React from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useParams } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import Sidebar from '../components/Sidebar';
import SideDefault from '../components/SideDefault';

const BillingAddress = () => {
    const params = useParams();
    const { data, dataLoading } = useFetch(`${endPoints.GET_BUSINESS_ACCOUNT}/${params?.name?.split("_")[0]}`);

    return (
        <>
            <MainHeader />

            {!dataLoading &&
                <div className="s-layout">
                    <SideDefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row ">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4">Business Address</h4>
                                                        <form action="#" className="mt-4">
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Address 1</label>
                                                                <textarea
                                                                    value={data?.address_1}
                                                                    className="form-control"
                                                                    rows={2}
                                                                    disabled
                                                                    // placeholder="Address 1"
                                                                    spellCheck="false"
                                                                    defaultValue={""}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Address 2</label>
                                                                <textarea
                                                                    value={data?.address_2}
                                                                    disabled
                                                                    className="form-control"
                                                                    rows={2}
                                                                    // placeholder="Address 2"
                                                                    spellCheck="false"
                                                                    defaultValue={""}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> City</label>
                                                                <input
                                                                    value={data?.city}
                                                                    disabled
                                                                    type="text"
                                                                    className="form-control"
                                                                // placeholder="Enter City"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">

                                                                    Region Code
                                                                </label>
                                                                <input
                                                                    disabled
                                                                    value={data?.region_code}
                                                                    type="text"
                                                                    className="form-control"
                                                                // placeholder="Region Code"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Zip Code</label>
                                                                <input
                                                                    disabled
                                                                    value={data?.zip_code}
                                                                    type="text"
                                                                    className="form-control"
                                                                // placeholder="Zip Code"
                                                                />
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={dataLoading}
            />

        </>
    )
}

export default BillingAddress