import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import endPoints from '../constants';
import { toast } from 'react-toastify';
import { Modal } from 'react-responsive-modal';
import useFetch from '../hooks/useFetch';
import { Bars } from 'react-loader-spinner';
import SideDefault from '../components/SideDefault';
import MainHeader from '../components/MainHeader';

const FundTransaction = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [openModal, setOpenModal] = useState(false);
    const onOpenModal = () => setOpenModal(true);
    const onCloseModal = () => setOpenModal(false);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { data, dataLoading } = useFetch(`${endPoints.ACH_PAYMENTS}?type=incoming%2Cexternal&account_id=${params?.acc_id}`);
    const { data: accountData, dataLoading: accountDataLoading } = useFetch(`${endPoints.ALL_ACCOUNTS}/${params?.acc_id?.split("_")[0]}`);

    const [accDetail,setAccDetail]=useState(null);
    const [formData, setFormData] = useState({
        amount: '',
        description: ''
    });
    const [errors, setErrors] = useState({
        amount: '',
        description: ''
    });

    useEffect(()=>{
        let aid=params?.label_id?.split("_")[0];
         if(data){
            let foundAccount=data?.data?.find((item)=>{
                if(aid==item?.attributes?.uuid){
                    return item
                }
            });
             if (foundAccount) setAccDetail(foundAccount);
         
         }
    },[data])
    const handleChange = (name, value) => {

        setFormData({
            ...formData,
            [name]: value
        });


        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = true;
        let newErrors = {
            amount: '',
            description: ''
        };
        if (!formData.amount) {
            newErrors.amount = 'Amount is required';
            formIsValid = false;
        }
        if (!formData.description) {
            newErrors.description = 'Description is required';
            formIsValid = false;
        }

        setErrors(newErrors);

        if (!formIsValid) {
            return
        }
        try {
            setLoading(true);
            let data = {
                "label": accDetail?.attributes?.label,
                "account_number": accDetail?.attributes?.account_number,
                "routing_number": accDetail?.attributes?.routing_number,
                "bank_name": accDetail?.attributes?.bank_name,
                "account_type": accDetail?.attributes?.account_type,
                "amount": formData?.amount,
                "type": "outgoing",
                "account_id":accountData?.data?.attributes?.uuid,
                "sent_from": accountData?.data?.attributes?.label,
                "purpose": formData?.description,
                "payment_id": accDetail?.attributes?.uuid,
            }
    
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MAKE_PAY_VIA_ACH_TP}`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            setLoading(false);

            if (resp.status === 200) {
                toast.success("Transaction Successful ");
                navigate(`/move-fund-to-another-account/${params?.acc_id}`, {replace: true });
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    };
    const handleDelete = async () => {
        try {
            setDeleteLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}/${endPoints.ACH_PAYMENTS}/${params?.label_id?.split("_")[0]}`,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp?.status == 200) {
                toast.success("External account deleted successfully");
                onCloseModal();
                navigate(`/move-fund-to-another-account/${params?.acc_id}`, {  replace: true });
            }

            setDeleteLoading(false);
        } catch (error) {
            setDeleteLoading(false)
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
            console.log(error)
        }
    }
    return (
        <>
            <MainHeader />
            {!dataLoading && <div className="s-layout">
                <SideDefault />
                <main className="s-layout__content">
                    <div className="main-page">
                            <div className="row ">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <div className="d-flex justify-content-between">

                                                        <h4 className="">Funds Transaction</h4>
                                                           
                                                            <div className="add-btn cursor-pointer " onClick={onOpenModal}>
                                                                <a className=" " >Delete</a>

                                                            </div>
                                                        </div>
                                                        <form onSubmit={handleSubmit} className="mt-4">

                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Source</label>
                                                                <div className="input-group">
                                                                <span className="form-control">XXXXXX{accountData?.data?.attributes?.tp_account_number?.slice(-4)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Destination</label>
                                                                <div className="input-group">
                                                                    <span className="form-control">XXXXXX{accDetail?.attributes?.account_number?.slice(-4)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Amount</label>
                                                                <CurrencyInput
                                                                    className={`form-control `}
                                                                    name="amount"
                                                                    prefix="$"
                                                                    placeholder="Amount"
                                                                    decimalsLimit={2}
                                                                    value={formData.amount}
                                                                    onValueChange={(value, name) => handleChange(name, value)}
                                                                />
                                                                {errors.amount && <div className="text-danger">{errors.amount}</div>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Description</label>
                                                                <textarea
                                                                    name="description"
                                                                    className={`form-control `}
                                                                    placeholder="Description"
                                                                    value={formData.description}
                                                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                                />
                                                                {errors.description && <div className="text-danger">{errors.description}</div>}
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="btn" disabled={loading}>
                                                                    {loading?"Loading":"submit"}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </main>
            </div>}
            <Modal open={openModal} onClose={onCloseModal} center>
                <div className="p-5">

                    <div className="">
                        <h4 className="mb-4 ">Are you sure you want to delete this account? </h4>
                    </div>
                    <div className="d-flex  justify-content-center gap-3 ">

                        <button className="whit-btn" onClick={handleDelete} disabled={deleteLoading}>{deleteLoading ? "Deleting..." : "Sure"}</button>
                        <button className="blk-btn" onClick={onCloseModal}>Cancel</button>

                    </div>

                </div>
            </Modal>
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={dataLoading}
            />
        </>
    );
}

export default FundTransaction;
