import useFetch from "../hooks/useFetch";
import endPoints from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { formatCurrency } from "../hooks/dollarFormatter";
import FetchStaffCards from "./FetchStaffCards";
import { useEffect, useState } from "react";
import { decryptData } from "../hooks/encryptionUtils";
import { useAuth } from "../components/AuthContext";
import SideDefault from "../components/SideDefault";
import { toast } from "react-toastify";
import MainHeader from "../components/MainHeader";

const Dashboard = () => {
  const navigate = useNavigate();
  // console.log("process.env.BASE_URL");
  const { userRole } = useAuth();
  const [fetchBusiness, setFetchBusiness] = useState(false);
  const [decryptedrRole, setDecryptedRole] = useState(null);
  const [customerGroup, setCustomerGroup] = useState(false);
  const showAccount = (item) => {
    if (item?.kyb_status?.toLowerCase() === "approved") {
      let slug =
        item?.uuid + "_" + item?.legal_name?.trim()?.replaceAll(" ", "-");
      navigate(`/${slug}/accounts`, {
        state: { uuid: item?.uuid, item, slug },
      });
    } else {
      return toast.error(`KYB not approved yet`);
    }
  };
  const handleDecrypt = () => {
    let user_role = localStorage.getItem("skyrole");

    // console.log("itemmm=========name", item);
    if (user_role) {
      try {
        const decrypted = decryptData(user_role);
        setDecryptedRole(decrypted);
        if (decrypted == "card_only") setFetchBusiness(false);
        else setFetchBusiness(true);
      } catch (error) {
        console.error("Decryption error:", error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    handleDecrypt();
  }, []);

  const shortName = (name) => {
    name = name?.split(" ");
    // console.log("name");
    let res = name[0][0]?.toUpperCase();
    // let res = name?.length > 1 ? name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase() : name[0][0]?.toUpperCase()
    return res;
  };
  // console.log(data);
  const { data, dataLoading } = useFetch(endPoints.ALL_BUSINESS, fetchBusiness);

  useEffect(() => {
    let userData = localStorage.getItem("user");

    if (userData) {
      try {
        const decrypted = decryptData(userData);

        if (data?.length > 0 && decrypted?.customerGroup == 2) {
          setCustomerGroup(false);
        } else setCustomerGroup(true);
      } catch (error) {
        console.error("Decryption error:", error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  }, [data]);

  return (
    <>
      <MainHeader />
      <div className="s-layout">
        {/* row */}
        {!dataLoading && decryptedrRole != "card_only" && (
          <>
            <SideDefault />
            <main className="s-layout__content">
              <div className="main-page">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card plan-list no-bg">
                      <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                        <div className="me-auto pe-3 mb-3">
                          <h4 className="text-black fs-26 mb-0">
                            <svg
                              width="37"
                              height="32"
                              viewBox="0 0 37 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_1428_179)">
                                <path
                                  d="M1.58984 23.5748L17.2725 7.89209L26.0207 18.5606L32.7418 9.27899"
                                  stroke="#38AFFF"
                                  stroke-width="1.90566"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M36.9452 4.89521L37.012 11.3427C37.0222 12.3313 35.927 12.9327 35.0983 12.3936L29.1104 8.4985C28.2817 7.95943 28.3876 6.71444 29.2954 6.3231L35.2166 3.77076C36.029 3.42056 36.936 4.01056 36.9452 4.89521Z"
                                  fill="#38AFFF"
                                  stroke="#38AFFF"
                                  stroke-width="0.0640109"
                                />
                                <line
                                  x1="9.34294"
                                  y1="25.5141"
                                  x2="18.5825"
                                  y2="16.6314"
                                  stroke="#38AFFF"
                                  stroke-width="1.90566"
                                  stroke-linecap="round"
                                />
                                <line
                                  x1="16.5773"
                                  y1="26.7299"
                                  x2="22.1253"
                                  y2="21.3962"
                                  stroke="#38AFFF"
                                  stroke-width="1.90566"
                                  stroke-linecap="round"
                                />
                                <line
                                  x1="25.9965"
                                  y1="25.616"
                                  x2="25.9803"
                                  y2="25.6332"
                                  stroke="#38AFFF"
                                  stroke-width="1.90566"
                                  stroke-linecap="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1428_179">
                                  <rect
                                    width="37"
                                    height="30.1481"
                                    fill="white"
                                    transform="translate(0 0.925781)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Businesses
                          </h4>
                        </div>
                        {userRole != "admin" &&
                          userRole != "edit_account" &&
                          userRole != "view_account" &&
                          userRole != "card_only" &&
                          customerGroup && (
                            <div className=" create-account">
                              <Link to={"/create-business"}>
                                Add New Business
                              </Link>
                            </div>
                          )}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {data?.length > 0 &&
                            data?.map((item, ind) => {
                              // console.log(item);
                              return (
                                <div
                                  className="col-md-4 col-sm-6 cursor-pointer buss-page"
                                  key={ind}
                                  onClick={() => showAccount(item)}
                                >
                                  <a>
                                    <div className="flex-wrap align-items-center card-box cursor-pointer">
                                      {/* <div className="col-xl-12 align-items-center"> */}
                                      <div className="list-icons">
                                        {shortName(
                                          item?.title ?? item?.legal_name
                                        )}
                                      </div>
                                      <div className="info mb-3 mt-3">
                                        <h4 className="fs-24 mb-0 text-black text-capitalize">
                                          {item?.title ?? item?.legal_name}
                                        </h4>
                                        {/* <span>Lorem ipsum dolor sit amet</span> */}
                                      </div>
                                      {/* </div> */}
                                      <div className="activities ps-3 pe-3 col-12">
                                        <h3 className="fs-16 mb-0 mob-value">
                                          Total Value
                                        </h3>
                                        <h3 className="text-info fs-28">
                                          {formatCurrency(
                                            item?.total_remaining_balance ?? 0
                                          )}
                                        </h3>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                          {data?.length == 0 && (
                            <p className="">No Data Found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        )}
      </div>
      {!dataLoading && decryptedrRole == "card_only" && <FetchStaffCards />}
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={dataLoading}
      />
    </>
  );
};

export default Dashboard;
