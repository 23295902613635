import React, { useState, useEffect } from 'react';
import MainHeader from '../components/MainHeader';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import endPoints from '../constants';
import useFetch from '../hooks/useFetch';
import {  useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatCurrency } from '../hooks/dollarFormatter';
import { getCurrentDate, getFirstDateOfMonth } from '../hooks/getDates';
import { Bars } from 'react-loader-spinner';
import Sidebar from '../components/Sidebar';
import SideDefault from '../components/SideDefault';

ChartJS.register(ArcElement, Tooltip, Legend);

const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const generateColors = (length) => {
    return Array.from({ length }, generateRandomColor);
};

const SpendingPatterns = () => {

    const params = useParams();
  
    const [count, setCount] = useState(1);
    const [date, setDate] = useState({
        from: getFirstDateOfMonth(),
        to: getCurrentDate()
    });

    const { data: spendingPatterns, dataLoading: spendingDataLoading } = useFetch(
        `${endPoints.GET_EXPENSE_CATEGORIES}?account_id=${params?.acc_id}&from=${date?.from}&to=${date?.to}`,
        count
    );

    const datasetValues = spendingPatterns?.map(item => item?.percent) || [];
    const backgroundColors = generateColors(datasetValues.length);

    const data = {
        datasets: [
            {
                data: datasetValues,
                backgroundColor: backgroundColors,
                borderColor: backgroundColors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (date.from.trim() !== "" && date.to.trim() !== "") {
            setCount(count + 1);
        } else {
            toast.error("Both Dates Required");
        }
    };

    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in yyyy-mm-dd format

    return (
        <>
            <MainHeader />
            {!spendingDataLoading && <div className="s-layout">
                <SideDefault />
                <main className="s-layout__content">
                    <div className="main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-3">Spending Patterns</h4>
                                            </div>
                                        </div>
                                        <div className="card-body pt-2">
                                            <div className="row">
                                                <form onSubmit={handleSearch}>
                                                    <div className="col-md-12 search-dates">
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={date.from}
                                                            max={currentDate}
                                                            onChange={(e) => {
                                                                setDate({ ...date, from: e.target.value });
                                                            }}
                                                        />
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={date.to}
                                                            max={currentDate}
                                                            onChange={(e) => {
                                                                setDate({ ...date, to: e.target.value });
                                                            }}
                                                        />
                                                        <button className="btn">Search</button>
                                                    </div>
                                                </form>
                                                <div className="col-md-12 skyfi-cart">
                                                    {!spendingDataLoading && datasetValues.length > 0 ? (
                                                        <Doughnut data={data} options={options} />
                                                    ) : (
                                                        <p>No data available for the selected dates.</p>
                                                    )}
                                                </div>
                                                <div className="col-md-12 progress-bar-main">
                                                    {spendingPatterns && spendingPatterns?.map((item, index) => (
                                                        <div className="progress-bar-box" key={index}>
                                                            <h6>
                                                                {item.expense_category}
                                                                <br />
                                                                <small>Total Transactions : {item?.transaction_count}</small>
                                                                <span className="pull-right"> {formatCurrency(item?.total_amount)}</span>
                                                            </h6>
                                                            <div className="progress">
                                                                <div
                                                                    className="progress-bar progress-animated"
                                                                    style={{ width: `${(item?.percent / datasetValues.reduce((a, b) => a + b, 0)) * 100}%`, height: 30, backgroundColor: backgroundColors[index] }}
                                                                    role="progressbar"
                                                                >
                                                                    <span className="sr-only">{((item?.percent / datasetValues.reduce((a, b) => a + b, 0)) * 100).toFixed(2)}% Complete</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </main>
            </div>}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={spendingDataLoading}
            />
        </>
    );
};

export default SpendingPatterns;
