import "./App.css";
import "./fonts/ClashGrotesk-Regular.otf"
import Login from "./pages/Login";
import "./assets/css/bootstrap-select.min.css";
import "./assets/css/style.css";
import Layout from "./components/Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Protected } from "./components/Protected";
import Register from "./pages/Register";
import RegisterPersonalDetail from "./pages/RegisterPersonalDetail";
import RegisterPersonalAddress from "./pages/RegisterPersonalAddress";
import IdentityVerification from "./pages/IdentityVerification";
import RedirectComponent from "./components/RedirectComponent";
import OtpVerification from "./pages/OtpVerification";
import BusinessDetails from "./pages/BusinessDetails";

function App() {
  window.addEventListener('wheel', function(event) {
    if (event.ctrlKey) {
      event.preventDefault();
      // Add your custom logic here
    }
  }, { passive: false });

window.addEventListener('keydown', function(event) {
  if (event.ctrlKey && (event.key === '+' || event.key === '-')) {
    event.preventDefault();
  }
});
  return (
    <div id="main-wrapper" className="accounts-part ">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/otp-verify" element={<OtpVerification />} />
          <Route
            path="/identity-verification"
            element={<IdentityVerification />}
          />
          <Route path="/register" element={<Register />} />
          <Route
            path="/register-personal-detail"
            element={<RegisterPersonalDetail />}
          />
          <Route
            path="/register-personal-address"
            element={<RegisterPersonalAddress />}
          />
          <Route
            path="/register-business-details"
            element={<BusinessDetails />}
          />
          <Route
            path="/redirect"
            element={<RedirectComponent />}
          />

          <Route path="/*" element={<Protected Component={Layout} />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default App;
