import React, { useState } from 'react'
import { CreateBusiness } from './CreateBusiness';
import BusinessAddress from './BusinessAddress';
import SimpleHeader from '../components/SimpleHeader';

const BusinessFormType = {
    businessDetails: "Business-Details",
    businessAddress: "Business-Address"
}
const BusinessDetails = () => {
    const [state, setState] = useState({
        currentForm: BusinessFormType.businessDetails,
    })
    return (
        <>
        {/* <SimpleHeader /> */}
            <div className='container h-100 pt-5'>
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="card login-part">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form">
                                            <div className='d-flex justify-content-between'>
                                                <h4 className="mb-4">
                                                    Create New Business
                                                </h4>
                                            </div>
                                            <div className="form-container abd">
                                                {state.currentForm === BusinessFormType.businessDetails && (
                                                    <div className={`form-content show`}>
                                                        <CreateBusiness editable={true} onNextClick={() => {
                                                            setState((state) => ({
                                                                ...state,
                                                                currentForm: BusinessFormType.businessAddress
                                                            }));
                                                        }} />
                                                    </div>
                                                )}
                                                {state.currentForm === BusinessFormType.businessAddress && (
                                                    <div className={`form-content show`}>
                                                        <BusinessAddress editable={true} onPreviousClick={() => {
                                                            setState((state) => ({
                                                                ...state,
                                                                currentForm: BusinessFormType.businessDetails
                                                            }));
                                                        }} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessDetails
