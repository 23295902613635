import React, { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import SideDefault from '../components/SideDefault';
import endPoints from '../constants';
import useMutation from '../hooks/useMutation';
import BusinessAddress from './BusinessAddress';
import { CreateBusiness } from './CreateBusiness';
import MainHeader from '../components/MainHeader';

const BusinessFormType = {
  businessDetails: "Business-Details",
  businessAddress: "Business-Address"
}

const BusinessForm = () => {
  
  const params = useParams();

  const [state, setState] = useState({
    currentForm: BusinessFormType.businessDetails,
    editable: false,
  })

  const { data: businessDetails, loading, mutate: fetchBusinessDetails } = useMutation(`${endPoints.ALL_BUSINESS}/${params?.b_id}`)

  // Fetch business data
  useEffect(() => {
    if (businessDetails) {
      businessDetails.account = ""
    }
    if (window.location.pathname.includes("update-business") && !businessDetails) {
      fetchBusinessDetails();
    }
    else {
      setState((prev) => ({
        ...prev,
        editable: true,
      }));
    }
  }, [window.location.pathname]);



  return (
    <>
      <MainHeader />
      <div className="s-layout">
        <SideDefault />
        <main className="s-layout__content">
          <div className="main-page">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-lg-8 col-md-10">
                <div className="card login-part">
                  <div className="authincation-content">
                    <div className="row no-gutters">
                      <div className="col-xl-12">
                        <div className="auth-form">
                          <div className='d-flex justify-content-between'>
                            <h4 className="mb-4"> <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"><path fill="currentColor" d="M4.615 20q-.69 0-1.152-.462Q3 19.075 3 18.385v-9.77q0-.69.463-1.152Q3.925 7 4.615 7H9V5.615q0-.69.463-1.152Q9.925 4 10.614 4h2.77q.69 0 1.153.463q.462.462.462 1.152V7h4.385q.69 0 1.152.463q.463.462.463 1.152v9.77q0 .69-.462 1.152q-.463.463-1.153.463H4.615ZM10 7h4V5.615q0-.23-.192-.423Q13.615 5 13.385 5h-2.77q-.23 0-.423.192q-.192.193-.192.423V7Zm10 7.5h-6v.692q0 .348-.23.578q-.23.23-.578.23h-2.384q-.348 0-.578-.23q-.23-.23-.23-.578V14.5H4v3.885q0 .23.192.423q.193.192.423.192h14.77q.23 0 .423-.192q.192-.193.192-.423V14.5Zm-9 .5h2v-2h-2v2Zm-7-1.5h6v-.692q0-.348.23-.578q.23-.23.578-.23h2.384q.348 0 .578.23q.23.23.23.578v.692h6V8.615q0-.23-.192-.423Q19.615 8 19.385 8H4.615q-.23 0-.423.192Q4 8.385 4 8.615V13.5Zm8 .5Z"/></svg>
                              {window?.location?.pathname?.includes("update-business") ? "Update Business" : "Create New Business"}
                            </h4>
                            {!state.editable && !window?.location?.pathname?.includes("create-business") && (
                              <button type="button" className="btn" onClick={() => {
                                setState((prev) => ({
                                  ...prev,
                                  editable: true,
                                }));
                              }}>
                                Request Changes
                              </button>
                            )}
                          </div>
                          <div className="form-container abd">
                            {state.currentForm === BusinessFormType.businessDetails && (
                              <div className={`form-content show`}>
                                <CreateBusiness business={businessDetails} editable={state.editable} onNextClick={() => {
                                  setState((state) => ({
                                    ...state,
                                    currentForm: BusinessFormType.businessAddress
                                  }));
                                }} />
                              </div>
                            )}
                            {state.currentForm === BusinessFormType.businessAddress && (
                              <div className={`form-content show`}>
                                <BusinessAddress business={businessDetails} editable={state.editable} onPreviousClick={() => {
                                  setState((state) => ({
                                    ...state,
                                    currentForm: BusinessFormType.businessDetails
                                  }));
                                }} />
                              </div>
                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={loading}
      />
    </>
  );
};

export default BusinessForm;
